<template>
  <div
    ref="conversationWindowWrapper"
    class="d-flex flex-column-reverse px-2"
    style="height: calc(100vh - 130px); overflow-y: auto"
    @scroll="handleScroll"
  >
    <div
      v-for="(message, i) in messages.list"
      :key="message.IdMsg"
      class="py-2"
    >
      <p v-if="showDate(i)" class="chat-date mt-2 text-center mx-auto">
        {{ handleShowDate(message) }}
      </p>

      <div
        class="message-chip"
        :class="{
          'message-chip-local':
            message.FromClient === 0 && message.FromSite === 0,
          'repeat-chip': message.Msg && message.Msg.startsWith('@*@'),
        }"
      >
        <div class="d-flex flex-column">
          <div class="d-flex justify-space-between align-center mb-2">
            <div class="mr-2">
              <new-ocorrency-dialog
                :snackbar="snackbar"
                :chatlog="getLastFiveMessages(i)"
              >
                <template v-slot:default="{ on: onDialog, attrs: attrsDialog }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="
                          message.FromClient === 0 && message.FromSite === 0
                            ? 'white'
                            : 'black'
                        "
                        x-small
                        icon
                        v-bind="{ ...attrs, ...attrsDialog }"
                        v-on="{ ...on, ...onDialog }"
                      >
                        <v-icon x-small>mdi-file-document-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Criar ocorrência</span>
                  </v-tooltip>
                </template>
              </new-ocorrency-dialog>
              <v-tooltip v-if="message.ExameID" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="
                      message.FromClient === 0 && message.FromSite === 0
                        ? 'white'
                        : 'black'
                    "
                    x-small
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="handleOpenECG(message)"
                  >
                    <v-icon x-small>mdi-target-account</v-icon>
                  </v-btn>
                </template>
                <span>Ir para exame</span>
              </v-tooltip>
            </div>
            <div class="text-caption">
              {{ handleDate(message) }} por {{ message.FromUsername }}
            </div>
          </div>
          <div v-linkified>
            {{
              message.Msg && message.Msg.startsWith("@*@")
                ? message.Msg.substring(3)
                : message.Msg
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewOcorrencyDialog from "@/components/NewOcorrencyDialog.vue";
import { differenceInCalendarDays, parseISO } from "date-fns";
import { mapGetters } from "vuex";

import ChatService from "@/services/Chat";
import { formatDate } from "@/utils/formatDate";

export default {
  name: "ChatConversationWindow",

  components: { NewOcorrencyDialog },

  props: {
    snackbar: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    messageStruct: {},
    messages: {
      list: [],
      hasMoreMsgs: true,
    },
  }),

  computed: {
    ...mapGetters(["activeContact"]),
  },

  sockets: {
    "new::message"(message) {
      let idx;
      if (message.FromClient === 0 && message.FromSite === 0) {
        idx = `C${message.ToClient}L${message.ToSite}`;
      } else {
        idx = `C${message.FromClient}L${message.FromSite}`;
      }
      const loadedMsgList = Object.keys(this.messageStruct);
      if (loadedMsgList.includes(idx)) {
        this.messageStruct[idx].list.unshift(message);

        this.messageStruct[idx].list = this.messageStruct[idx].list.sort(
          (a, b) => b.IdMsg - a.IdMsg,
        );
      }
    },
  },

  watch: {
    activeContact(n) {
      if (n.clientId === null || n.localId === null) return;

      const idx = `C${n.clientId}L${n.localId}`;
      const loadedMsgList = Object.keys(this.messageStruct);
      if (!loadedMsgList.includes(idx)) {
        ChatService.getMessages(n.clientId, n.localId)
          .then(async (response) => {
            if (response.status === 200 || response.status === 204) {
              let msgs = response.data || [];

              const unread = msgs
                .filter((m) => m.ChatMsgsReadWeb === false)
                .map((m) => m.IdMsg);

              if (unread.length > 0) {
                ChatService.markAsRead(unread)
                  .then((response) => {
                    if (response.status !== 200) {
                      this.snackbar.show = false;
                      this.$nextTick(() => {
                        this.snackbar.show = true;
                        this.snackbar.text =
                          "Ocorreu um erro ao enviar informação para o servidor!";
                        this.snackbar.color = "error";
                        this.snackbar.timeout = 5000;
                      });
                      return;
                    }
                  })
                  .catch((_) => {
                    this.snackbar.show = false;
                    this.$nextTick(() => {
                      this.snackbar.show = true;
                      this.snackbar.text =
                        "Ocorreu um erro ao enviar informação para o servidor!";
                      this.snackbar.color = "error";
                      this.snackbar.timeout = 5000;
                    });
                    return;
                  });

                msgs = msgs.map((m) => {
                  if (unread.includes(m.IdMsg)) {
                    m.ChatMsgsReadWeb = true;
                  }
                  return m;
                });

                this.$emit("mark-as-read-last", this.activeContact);
              }

              this.messages = {
                list: msgs,
                hasMoreMsgs: true,
              };
              this.messageStruct[idx] = {
                list: msgs,
                hasMoreMsgs: true,
              };
            } else {
              this.snackbar.show = false;
              this.$nextTick(() => {
                this.snackbar.show = true;
                this.snackbar.text =
                  "Ocorreu um erro ao recolher informação para o servidor!";
                this.snackbar.color = "error";
                this.snackbar.timeout = 5000;
              });
              return;
            }
          })
          .catch((_) => {
            this.snackbar.show = false;
            this.$nextTick(() => {
              this.snackbar.show = true;
              this.snackbar.text =
                "Ocorreu um erro ao enviar informação para o servidor!";
              this.snackbar.color = "error";
              this.snackbar.timeout = 5000;
            });
            return;
          });
      } else {
        this.messages = this.messageStruct[idx];
      }

      ChatService.notifyRead(this.activeContact)
        .then((response) => {
          if (response.status !== 200) {
            this.snackbar.show = false;
            this.$nextTick(() => {
              this.snackbar.show = true;
              this.snackbar.text =
                "Ocorreu um erro ao enviar informação para o servidor!";
              this.snackbar.color = "error";
              this.snackbar.timeout = 5000;
            });
            return;
          }
        })
        .catch((_) => {
          this.snackbar.show = false;
          this.$nextTick(() => {
            this.snackbar.show = true;
            this.snackbar.text =
              "Ocorreu um erro ao enviar informação para o servidor!";
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
          });
          return;
        });

      const c = this.$refs.conversationWindowWrapper;
      c.scrollTop = c.scrollHeight;
    },
  },

  methods: {
    formatDate,
    parseISO,
    getLastFiveMessages(index) {
      const startIndex = Math.max(0, index - 3);

      const endIndex = index + 3;

      return [...this.messages.list].slice(startIndex, endIndex);
    },
    handleOpenECG(msg) {
      if (msg.TipoExame === "ECG" || !msg.TipoExame) {
        window.open(
          process.env.VUE_APP_ECG_REDIRECT_URL +
            encodeURIComponent(msg.ExameID),
          "_blank",
        );
      } else if (msg.TipoExame === "MAPA") {
        window.open(
          process.env.VUE_APP_MAPA_REDIRECT_URL +
            encodeURIComponent(msg.ExameID),
          "_blank",
        );
      } else if (msg.TipoExame === "HOLTER") {
        window.open(
          process.env.VUE_APP_HOLTER_REDIRECT_URL +
            encodeURIComponent(msg.ExameID),
          "_blank",
        );
      }
    },
    showDate(i) {
      const thisMsg = this.messages.list[i];
      if (i === this.messages.list.length - 1) return true;
      const afterMsg = this.messages.list[i + 1];

      let thisDate;
      let afterDate;
      if (thisMsg.DisplayDate && afterMsg.DisplayDate) {
        thisDate = parseISO(thisMsg.DisplayDate);
        afterDate = parseISO(afterMsg.DisplayDate);
      } else {
        thisDate = parseISO(thisMsg.DateTime);
        afterDate = parseISO(afterMsg.DateTime);
      }

      if (differenceInCalendarDays(afterDate, thisDate) < 0) return true;
      return false;
    },
    handleScroll(el) {
      if (this.messages.hasMoreMsgs === false) return;

      if (
        el.srcElement.offsetHeight + Math.abs(el.srcElement.scrollTop) >=
        el.srcElement.scrollHeight
      ) {
        const idx = `C${this.activeContact.clientId}L${this.activeContact.localId}`;
        ChatService.getMessages(
          this.activeContact.clientId,
          this.activeContact.localId,
          this.messages.list[this.messages.list.length - 1].IdMsg,
        )
          .then((response) => {
            if (response.status === 200) {
              const msgs = response.data;
              this.messages.list = this.messages.list.concat(msgs);
            } else if (response.status === 204) {
              this.messages.hasMoreMsgs = false;
            } else {
              this.messages.hasMoreMsgs = false;
              this.snackbar.show = false;
              this.$nextTick(() => {
                this.snackbar.show = true;
                this.snackbar.text =
                  "Ocorreu um erro ao enviar informação para o servidor!";
                this.snackbar.color = "error";
                this.snackbar.timeout = 5000;
              });
              return;
            }
            this.messageStruct[idx] = this.messages;
          })
          .catch((_) => {
            this.messages.hasMoreMsgs = false;
            this.snackbar.show = false;
            this.$nextTick(() => {
              this.snackbar.show = true;
              this.snackbar.text =
                "Ocorreu um erro ao enviar informação para o servidor!";
              this.snackbar.color = "error";
              this.snackbar.timeout = 5000;
            });
            this.messageStruct[idx] = this.messages;
            return;
          });
      }
    },
    sendMessageUpdate(e) {
      const idx = `C${this.activeContact.clientId}L${this.activeContact.localId}`;
      this.messages.list.unshift(e);
      this.messageStruct[idx] = this.messages;
    },
    handleDate(message) {
      if (message.DisplayDate) {
        return this.formatDate(parseISO(message.DisplayDate + "Z"), "HH:mm");
      } else {
        return this.formatDate(parseISO(message.DateTime + "Z"), "HH:mm");
      }
    },
    handleShowDate(message) {
      if (message.DisplayDate) {
        return this.formatDate(
          parseISO(message.DisplayDate + "Z"),
          "dd/MM/yyyy",
        );
      } else {
        return this.formatDate(parseISO(message.DateTime + "Z"), "dd/MM/yyyy");
      }
    },
  },
};
</script>
