<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <slot justify="rigth" :attrs="attrs" :on="on"></slot>
    </template>
    <v-card class="pa-5" color="modalbackground">
      <v-card-title class="pb-1 px-4 d-flex align-start">
        <div>
          <div class="primary-text-color source-sans-pro fs-title semi-bold">
            Nova Ocorrência
          </div>
          <div class="source-sans-pro fs-secondary">
            {{ activeContact.clientName }} - {{ activeContact.localName }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-icon small @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-form @submit.prevent="createNewOcorrency">
        <v-card-text class="px-4">
          <div class="mt-3">
            <div
              class="
                primary-text-color
                semi-bold
                source-sans-pro
                fs-label
                d-flex
                align-center
              "
            >
              Descrição
            </div>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="description"
                  auto-grow
                  no-resize
                  label=" "
                  color="black"
                  dense
                  solo
                  flat
                  hide-details
                  rows="5"
                  class="
                    source-sans-pro
                    primary-text-color
                    fs-field
                    pt-1
                    mb-1
                    focus-elem
                    chat-scroll
                  "
                >
                  <template v-slot:append>
                    <v-icon v-if="description" small @click="description = ''"
                      >mdi-close
                    </v-icon>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn depressed class="fs-label" color="primary" type="submit" small>
            Gravar ocorrência
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { parseISO } from "date-fns";
import { formatDate } from "@/utils/formatDate";
import { mapGetters } from "vuex";

import ChatService from "@/services/Chat";

export default {
  name: "NewOccorencyDialog",

  props: {
    snackbar: {
      type: Object,
      default: () => ({}),
    },
    chatlog: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    description: "",
  }),

  computed: {
    ...mapGetters(["activeContact"]),
  },

  watch: {
    dialog() {
      this.description = this.chatlog
        .map((l) => {
          let date;
          if (l.DisplayDate) {
            date = this.formatDate(
              parseISO(l.DisplayDate + "Z"),
              "dd/MM/yyyy HH:mm",
            );
          } else {
            date = this.formatDate(
              parseISO(l.DateTime + "Z"),
              "dd/MM/yyyy HH:mm",
            );
          }

          return `[${date}] ${l.FromUsername}: ${
            l.Msg && l.Msg.startsWith("@*@") ? l.Msg.substring(3) : l.Msg
          }`;
        })
        .reverse()
        .join("\n");
    },
  },

  methods: {
    formatDate,
    parseISO,
    async createNewOcorrency() {
      try {
        await ChatService.newOccorrence(
          this.activeContact.clientId,
          this.activeContact.localId,
          this.description,
        );
        this.snackbar.show = true;
        this.snackbar.text = "Ocorrência adicionada com sucesso!";
        this.snackbar.color = "success";
        this.snackbar.timeout = 5000;
        this.dialog = false;
      } catch (err) {
        this.snackbar.show = false;
        this.$nextTick(() => {
          this.snackbar.show = true;
          this.snackbar.text =
            "Ocorreu um erro a enviar pedido para o servidor!";
          this.snackbar.color = "error";
          this.snackbar.timeout = 5000;
        });
      }
    },
  },
};
</script>
