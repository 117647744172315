import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    activeContact: {
      clientName: null,
      clientId: null,
      localName: null,
      localId: null,
    },
    userId: null,
    username: null,
  },
  mutations: {
    set_username(state, username) {
      state.username = username;
    },
    set_user_id(state, userId) {
      state.userId = userId;
    },
    set_active_contact(state, contact) {
      state.activeContact = contact;
    },
    reset_storage(state) {
      state.activeContact = {
        clientName: null,
        clientId: null,
        localName: null,
        localId: null,
      };
      state.userId = null;
      state.username = null;
    },
  },
  getters: {
    activeContact: (state) => {
      return state.activeContact;
    },
    userId: (state) => {
      return state.userId;
    },
    username: (state) => {
      return state.username;
    },
  },
});
