<template>
  <v-app>
    <MessageBox :props="snackbar"></MessageBox>
    <contact-bar ref="contactBar" :snackbar="snackbar" :contacts="contacts" />
    <template-bar
      ref="templateBar"
      :snackbar="snackbar"
      @send-template-message="sendTemplateMessage"
    />
    <v-main>
      <router-view
        ref="routerView"
        :snackbar="snackbar"
        :get-contact-drawer-status="() => $refs.contactBar.getDrawerState()"
        :get-template-drawer-status="() => $refs.templateBar.getDrawerState()"
        @toggle-contact-bar="toggleContactBar"
        @toggle-template-bar="toggleTemplateBar"
        @update-contact-bar-new-message="updateContactBarNewMessage"
        @mark-as-read-last="markAsReadLast"
      />
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import ContactBar from "@/components/ContactBar";
import MessageBox from "@/components/MessageBox";
import TemplateBar from "@/components/TemplateBar";
import ChatService from "@/services/Chat";

export default {
  name: "App",

  components: { ContactBar, TemplateBar, MessageBox },

  data: () => ({
    contacts: [],
    created: false,
    snackbar: {
      show: false,
      text: null,
      color: null,
      timeout: 5000,
    },
  }),

  computed: {
    ...mapGetters(["activeContact", "userId"]),
  },

  sockets: {
    "notify::read"(activeContact) {
      this.markAsReadLast(activeContact);
    },
    "update::window"({ userid: userId, client: clientId, site: localId }) {
      if (this.userId === userId) {
        this.$router.push({ path: "", query: { clientId, localId } });
      }
    },
    "new::message"(message) {
      for (let i = 0; i < this.contacts.length; i++) {
        const c = this.contacts[i];
        if (
          (message.FromClient === c.clientId &&
            message.FromSite === c.localId) ||
          (message.ToClient === c.clientId && message.ToSite === c.localId)
        ) {
          if (!c.lastMessage) {
            c.lastMessage = {};
          }

          c.lastMessage.id = message.IdMsg;
          c.lastMessage.message = message.Msg;
          c.lastMessage.fromCentral =
            message.FromClient === 0 && message.FromSite === 0;
          c.lastMessage.date = new Date(message.DisplayDate);

          if (
            this.activeContact.clientId === c.clientId &&
            this.activeContact.localId === c.localId
          ) {
            c.lastMessage.read = true;

            ChatService.notifyRead(this.activeContact)
              .then((response) => {
                if (response.status !== 200) {
                  this.snackbar.show = false;
                  this.$nextTick(() => {
                    this.snackbar.show = true;
                    this.snackbar.text =
                      "Ocorreu um erro ao enviar informação para o servidor!";
                    this.snackbar.color = "error";
                    this.snackbar.timeout = 5000;
                  });
                  return;
                }
              })
              .catch((_) => {
                this.snackbar.show = false;
                this.$nextTick(() => {
                  this.snackbar.show = true;
                  this.snackbar.text =
                    "Ocorreu um erro ao enviar informação para o servidor!";
                  this.snackbar.color = "error";
                  this.snackbar.timeout = 5000;
                });
                return;
              });
          } else {
            c.lastMessage.read = false;
          }

          if (message.FromClient !== 0 && message.FromSite !== 0) {
            this.$notification.show(
              c.clientName + " - " + c.localName,
              {
                body: c.lastMessage.message,
              },
              {},
            );
          }

          break;
        }
      }

      this.contacts = this.contacts.sort(
        (a, b) => b.lastMessage.id - a.lastMessage.id,
      );
    },
    error() {
      this.snackbar.show = false;
      this.$nextTick(() => {
        this.snackbar.show = true;
        this.snackbar.text =
          "Ocorreu um erro a recolher informação do servidor! Por favor atualize a página!";
        this.snackbar.color = "error";
        this.snackbar.timeout = 5000;
      });
      return;
    },
  },

  watch: {
    $route({ query }, _) {
      if (this.created === true) {
        this.load(query);
      }
    },
    "$socket.connected": function (connected) {
      if (connected === true) {
        this.$socket.client.emit("room::join", "CHATC0L0");
      }
    },
  },

  created() {
    ChatService.getContactList()
      .then((response) => {
        if (response.status === 200) {
          this.contacts = response.data;
          this.contacts = this.contacts.map((c) => {
            if (c.lastMessage.date !== null) {
              c.lastMessage.date = new Date(c.lastMessage.date);
            }
            return c;
          });
          this.load(this.$route.query);
          this.created = true;
        } else {
          this.snackbar.show = false;
          this.$nextTick(() => {
            this.snackbar.show = true;
            this.snackbar.text =
              "Ocorreu um erro a recolher informação do servidor!";
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
          });
          return;
        }
      })
      .catch((_) => {
        this.snackbar.show = false;
        this.$nextTick(() => {
          this.snackbar.show = true;
          this.snackbar.text =
            "Ocorreu um erro a recolher informação do servidor!";
          this.snackbar.color = "error";
          this.snackbar.timeout = 5000;
        });
        return;
      });
  },

  methods: {
    ...mapMutations({
      setActiveContact: "set_active_contact",
    }),
    load({ clientId, localId }) {
      if (!clientId || !localId || clientId === null || localId === null) {
        this.handleContactInitial();
        return;
      }

      const contact = this.getContact(clientId, localId);
      if (!contact) {
        this.handleContactInitial();
        return;
      }

      this.setActiveContact(contact);
      const index = this.contacts.findIndex(
        (c) =>
          c.clientId === this.activeContact.clientId &&
          c.localId === this.activeContact.localId,
      );
      this.contacts[index].lastMessage.read = true;
      if (!this.$socket.connected) {
        this.$socket.client.connect();
      }
    },
    handleContactInitial() {
      const { clientId, localId } = this.getInitialContact();
      this.$router.push({ path: "", query: { clientId, localId } });
    },
    getInitialContact() {
      let clientId;
      let localId;
      if (
        this.activeContact.clientId !== null &&
        this.activeContact.localId !== null
      ) {
        clientId = this.activeContact.clientId;
        localId = this.activeContact.localId;
      } else {
        clientId = this.contacts[0].clientId;
        localId = this.contacts[0].localId;
      }
      return {
        clientId,
        localId,
      };
    },
    getContact(clientId, localId) {
      return this.contacts.filter(
        (c) => c.clientId === +clientId && c.localId === +localId,
      )[0];
    },
    markAsReadLast(e) {
      const index = this.contacts.findIndex(
        (c) => c.clientId === e.clientId && c.localId === e.localId,
      );

      this.contacts[index].lastMessage.read = true;
    },
    toggleContactBar() {
      this.$refs.contactBar.toggleContactBar();
    },
    toggleTemplateBar() {
      this.$refs.templateBar.toggleTemplateBar();
    },
    updateContactBarNewMessage(e) {
      const index = this.contacts.findIndex(
        (c) =>
          (c.clientId === e.FromClient && c.localId === e.FromSite) ||
          (c.clientId === e.ToClient && c.localId === e.ToSite),
      );

      if (!this.contacts[index].lastMessage) {
        this.contacts[index].lastMessage = {};
      }

      this.contacts[index].lastMessage.id = e.IdMsg;
      this.contacts[index].lastMessage.message = e.Msg;
      this.contacts[index].lastMessage.read = e.ChatMsgsReadWeb;
      this.contacts[index].lastMessage.date = new Date(e.DisplayDate);

      this.contacts = this.contacts.sort(
        (a, b) => b.lastMessage.id - a.lastMessage.id,
      );
    },
    sendTemplateMessage(template) {
      this.$refs.routerView.sendTemplateMessage(template);
    },
  },
};
</script>
