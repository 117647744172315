<template>
  <v-navigation-drawer
    v-model="showDrawer"
    color="background3"
    app
    right
    class="chat-scroll"
    width="300px"
  >
    <template #prepend>
      <v-card color="background2" tile flat class="pa-3">
        <span class="source-sans-pro semi-bold fs-header">Templates</span>
      </v-card>
    </template>
    <v-list>
      <v-list-item
        v-for="(template, i) in templates"
        :key="i"
        link
        dense
        @click="handleTemplateClick(template)"
      >
        <v-list-item-title class="source-sans-pro fs-field">{{
          template.title
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import ChatService from "@/services/Chat";

export default {
  name: "TemplateBar",

  props: {
    snackbar: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    showDrawer: true,
    templates: [],
  }),

  created() {
    ChatService.getTemplates()
      .then((response) => {
        if (response.status === 200) {
          this.templates = response.data;
        } else {
          this.snackbar.show = false;
          this.$nextTick(() => {
            this.snackbar.show = true;
            this.snackbar.text =
              "Ocorreu um erro a recolher informação do servidor!";
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
          });
          return;
        }
      })
      .catch(() => {
        this.snackbar.show = false;
        this.$nextTick(() => {
          this.snackbar.show = true;
          this.snackbar.text =
            "Ocorreu um erro a recolher informação do servidor!";
          this.snackbar.color = "error";
          this.snackbar.timeout = 5000;
        });
        return;
      });
  },

  methods: {
    toggleTemplateBar() {
      this.showDrawer = !this.showDrawer;
    },
    getDrawerState() {
      return this.showDrawer;
    },
    handleTemplateClick(template) {
      this.$emit("send-template-message", template);
    },
  },
};
</script>
