import axios from "axios";

axios.defaults.withCredentials = true;

export default {
  async getInformation() {
    try {
      return await axios.get(`${process.env.VUE_APP_AUTH_API_ENDPOINT}`);
    } catch (e) {
      return e.response;
    }
  },
};
