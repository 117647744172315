import Vue from "vue";
import VueNativeNotification from "vue-native-notification";
import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";
import axios from "axios";
import linkify from "vue-linkify";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "./css/main.css";
import "./css/chat.css";
import "./css/fields.css";
import "./css/fonts.css";
import "./css/colors.css";
import "./css/tabs.css";
import "./css/animations.css";
import "./css/tables.css";
import "./css/menu.css";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      window.location = process.env.VUE_APP_AUTH_ENDPOINT;
      store.commit("reset_storage");
    } else {
      return error.response;
    }
  },
);

let socket;
if (process.env.VUE_APP_ENV === "production") {
  socket = io(process.env.VUE_APP_SOCKET_ENDPOINT, {
    path: process.env.VUE_APP_SOCKET_ENDPOINT_PATH,
  });
} else {
  socket = io(process.env.VUE_APP_SOCKET_ENDPOINT);
}

socket.disconnect();

Vue.directive("linkified", linkify);
Vue.use(VueSocketIOExt, socket, { store });
Vue.use(VueNativeNotification, {
  requestOnNotify: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
