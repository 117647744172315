import axios from "axios";

axios.defaults.withCredentials = true;

export default {
  getContactList() {
    try {
      return axios.get(process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin/list");
    } catch (e) {
      return e.response;
    }
  },
  getMessages(c, l, b = null) {
    const params = new URLSearchParams();
    params.append("clientId", c);
    params.append("localId", l);
    if (b !== null) params.append("base", b);

    try {
      return axios.get(process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin", {
        params: params,
      });
    } catch (e) {
      return e.response;
    }
  },
  sendMessage(clientId, localId, message) {
    try {
      return axios.post(process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin", {
        clientId,
        localId,
        message,
      });
    } catch (e) {
      return e.response;
    }
  },
  markAsRead(ids) {
    try {
      return axios.post(
        process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin/mark-as-read",
        {
          ids,
        },
      );
    } catch (e) {
      return e.response;
    }
  },
  notifyRead(activeContact) {
    try {
      return axios.post(
        process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin/notify",
        {
          clientId: activeContact.clientId,
          localId: activeContact.localId,
        },
      );
    } catch (e) {
      return e.response;
    }
  },
  getInfo(c, l) {
    const params = new URLSearchParams();
    params.append("clientId", c);
    params.append("localId", l);

    try {
      return axios.get(
        process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin/contact",
        {
          params: params,
        },
      );
    } catch (e) {
      return e.response;
    }
  },
  getTemplates() {
    try {
      return axios.get(
        process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin/templates",
      );
    } catch (e) {
      return e.response;
    }
  },
  newOccorrence(clientId, localId, description = null) {
    try {
      return axios.post(
        process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin/occorrencies",
        {
          clientId,
          localId,
          description,
        },
      );
    } catch (e) {
      return e.response;
    }
  },
  updateContacts(clientId, localId, contacts) {
    try {
      return axios.put(
        process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin/contact",
        {
          clientId,
          localId,
          contacts,
        },
      );
    } catch (e) {
      return e.response;
    }
  },
  updateOccorrency(id, description) {
    try {
      return axios.put(
        process.env.VUE_APP_CHAT_API_ENDPOINT + "/admin/occorrencies/" + id,
        {
          description,
        },
      );
    } catch (e) {
      return e.response;
    }
  },
};
