<template>
  <v-snackbar
    v-model="props.show"
    :color="props.color"
    :timeout="props.timeout"
    top
  >
    <span class="source-sans-pro fs-message-box">{{ props.text }}</span>
  </v-snackbar>
</template>

<script>
export default {
  name: "MessageBox",
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
