import AuthService from "@/services/Auth";
import store from "@/store";

export default async (_, __, next) => {
  const res = await AuthService.getInformation();
  if (res.status === 200 && res.data && res.data.chat === true) {
    store.commit("set_user_id", res.data.userId);
    if (res.data.exames && res.data.exames.username) {
      store.commit("set_username", res.data.exames.username);
    } else {
      store.commit("set_username", "Cardio-On");
    }
    next();
  } else {
    window.location = process.env.VUE_APP_AUTH_ENDPOINT;
    next(false);
  }
};
