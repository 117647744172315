var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"conversationWindowWrapper",staticClass:"d-flex flex-column-reverse px-2",staticStyle:{"height":"calc(100vh - 130px)","overflow-y":"auto"},on:{"scroll":_vm.handleScroll}},_vm._l((_vm.messages.list),function(message,i){return _c('div',{key:message.IdMsg,staticClass:"py-2"},[(_vm.showDate(i))?_c('p',{staticClass:"chat-date mt-2 text-center mx-auto"},[_vm._v(" "+_vm._s(_vm.handleShowDate(message))+" ")]):_vm._e(),_c('div',{staticClass:"message-chip",class:{
        'message-chip-local':
          message.FromClient === 0 && message.FromSite === 0,
        'repeat-chip': message.Msg && message.Msg.startsWith('@*@'),
      }},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-2"},[_c('div',{staticClass:"mr-2"},[_c('new-ocorrency-dialog',{attrs:{"snackbar":_vm.snackbar,"chatlog":_vm.getLastFiveMessages(i)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var onDialog = ref.on;
      var attrsDialog = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":message.FromClient === 0 && message.FromSite === 0
                          ? 'white'
                          : 'black',"x-small":"","icon":""}},'v-btn',Object.assign({}, attrs, attrsDialog),false),Object.assign({}, on, onDialog)),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-file-document-plus-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Criar ocorrência")])])]}}],null,true)}),(message.ExameID)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":message.FromClient === 0 && message.FromSite === 0
                      ? 'white'
                      : 'black',"x-small":"","icon":""},on:{"click":function($event){return _vm.handleOpenECG(message)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-target-account")])],1)]}}],null,true)},[_c('span',[_vm._v("Ir para exame")])]):_vm._e()],1),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.handleDate(message))+" por "+_vm._s(message.FromUsername)+" ")])]),_c('div',{directives:[{name:"linkified",rawName:"v-linkified"}]},[_vm._v(" "+_vm._s(message.Msg && message.Msg.startsWith("@*@") ? message.Msg.substring(3) : message.Msg)+" ")])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }