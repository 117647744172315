<template>
  <div class="d-flex flex-column chat-scroll" style="height: 100% !important">
    <chat-header
      :get-contact-drawer-status="getContactDrawerStatus"
      :get-template-drawer-status="getTemplateDrawerStatus"
      :toggle-contact-bar="toggleContactBar"
      :toggle-template-bar="toggleTemplateBar"
      :snackbar="snackbar"
    />
    <chat-conversation-window
      ref="chatConversationWindow"
      :snackbar="snackbar"
      @mark-as-read-last="markAsReadLast"
    />
    <chat-message-box
      ref="chatMessageBox"
      :snackbar="snackbar"
      @send-message-update="sendMessageUpdate"
    />
  </div>
</template>

<script>
import ChatConversationWindow from "@/components/ChatConversationWindow";
import ChatHeader from "@/components/ChatHeader";
import ChatMessageBox from "@/components/ChatMessageBox";

export default {
  name: "Chat",

  components: { ChatHeader, ChatMessageBox, ChatConversationWindow },

  props: {
    snackbar: {
      type: Object,
      default: () => ({}),
    },
    getContactDrawerStatus: {
      type: Function,
      default: () => {},
    },
    getTemplateDrawerStatus: {
      type: Function,
      default: () => {},
    },
  },

  methods: {
    toggleContactBar() {
      this.$emit("toggle-contact-bar");
    },
    toggleTemplateBar() {
      this.$emit("toggle-template-bar");
    },
    sendMessageUpdate(e) {
      this.$refs.chatConversationWindow.sendMessageUpdate(e);
      this.$emit("update-contact-bar-new-message", e);
    },
    markAsReadLast(e) {
      this.$emit("mark-as-read-last", e);
    },
    sendTemplateMessage(template) {
      this.$refs.chatMessageBox.sendMessage(template.message);
    },
  },
};
</script>
