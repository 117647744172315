<template>
  <v-app-bar fixed dense tile class="elevation-default pl-4" color="white" app>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :class="{ 'active-btn primary--text': getContactDrawerStatus() }"
          class="mr-1"
          small
          icon
          v-on="on"
          @click="toggleContactBar"
        >
          <v-icon small>mdi-book-open-blank-variant</v-icon>
        </v-btn>
      </template>
      <span>Mostrar / Esconder contactos</span>
    </v-tooltip>
    <span class="source-sans-pro semi-bold fs-header">
      {{ activeContact.clientName }} - {{ activeContact.localName }}
    </span>
    <v-spacer />
    <chat-header-contacts :snackbar="snackbar">
      <template v-slot:default="{ on: onDialog, attrs: attrsDialog }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="{ ...attrs, ...attrsDialog }"
              small
              icon
              class="mx-2"
              v-on="{ ...on, ...onDialog }"
            >
              <v-icon small>mdi-contacts</v-icon>
            </v-btn>
          </template>
          Contactos
        </v-tooltip>
      </template>
    </chat-header-contacts>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :class="{ 'active-btn primary--text': getTemplateDrawerStatus() }"
          small
          icon
          v-on="on"
          @click="toggleTemplateBar"
        >
          <v-icon small>mdi-menu</v-icon>
        </v-btn>
      </template>
      <span>Mostrar / Esconder templates</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

import ChatHeaderContacts from "@/components/ChatHeaderContacts";

export default {
  name: "ChatHeader",

  components: { ChatHeaderContacts },

  props: {
    getContactDrawerStatus: {
      type: Function,
      default: () => {},
    },
    getTemplateDrawerStatus: {
      type: Function,
      default: () => {},
    },
    toggleContactBar: {
      type: Function,
      default: () => {},
    },
    toggleTemplateBar: {
      type: Function,
      default: () => {},
    },
    snackbar: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters(["activeContact"]),
  },
};
</script>
