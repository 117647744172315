import Vue from "vue";
import VueRouter from "vue-router";

import Chat from "@/views/Chat";

import ChatGuard from "@/guards/Chat";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Chat",
    component: Chat,
    beforeEnter: ChatGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
