<template>
  <v-textarea
    v-model="message"
    placeholder="Mensagem"
    hide-details
    flat
    solo
    no-resize
    rows="4"
    class="source-sans-pro primary-text-color fs-secundary mt-1"
    @keydown.enter.exact.prevent
    @keyup.enter.exact="sendMessage()"
  >
    <template v-slot:append>
      <v-btn icon @click="sendMessage()">
        <v-icon :class="{ 'active-send-message': message !== '' }" size="24">
          mdi-send
        </v-icon>
      </v-btn>
    </template>
  </v-textarea>
</template>

<script>
import { mapGetters } from "vuex";

import ChatService from "@/services/Chat";

export default {
  name: "ChatMessageBox",

  props: {
    snackbar: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    message: "",
  }),

  computed: {
    ...mapGetters(["activeContact", "username"]),
  },

  methods: {
    sendMessage(message = this.message) {
      if (message === "") return;
      ChatService.sendMessage(
        this.activeContact.clientId,
        this.activeContact.localId,
        message,
      )
        .then((response) => {
          if (response.status === 200) {
            response.data.FromUsername = this.username;
            this.$emit("send-message-update", response.data);
            this.$socket.client.emit("chat::new", response.data);
            if (this.message === message) this.message = "";
          } else {
            this.snackbar.show = false;
            this.$nextTick(() => {
              this.snackbar.show = true;
              this.snackbar.text =
                "Ocorreu um erro ao enviar informação para o servidor!";
              this.snackbar.color = "error";
              this.snackbar.timeout = 5000;
            });
            return;
          }
        })
        .catch(() => {
          this.snackbar.show = false;
          this.$nextTick(() => {
            this.snackbar.show = true;
            this.snackbar.text =
              "Ocorreu um erro ao enviar informação para o servidor!";
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
          });
          return;
        });
    },
  },
};
</script>
