<template>
  <v-navigation-drawer
    v-model="showDrawer"
    color="background3"
    app
    class="chat-scroll"
    width="300px"
  >
    <template #prepend>
      <contact-bar-filter @update-filter="updateFilter" />
    </template>
    <contact-bar-list :contacts="filteredContacts" />
  </v-navigation-drawer>
</template>

<script>
import ContactBarList from "@/components/ContactBarList";
import ContactBarFilter from "@/components/ContactBarFilter";

export default {
  name: "ContactBar",

  components: {
    ContactBarList,
    ContactBarFilter,
  },

  props: {
    snackbar: {
      type: Object,
      default: () => ({}),
    },
    contacts: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    filter: {},
    showDrawer: true,
  }),

  computed: {
    filteredContacts: function () {
      if (Object.keys(this.filter).length === 0) return this.contacts;

      let contacts = this.contacts;
      if (this.filter.text) {
        contacts = contacts.filter((c) => {
          return (
            (c.clientName + " - " + c.localName)
              .trim()
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                this.filter.text
                  .trim()
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, ""),
              ) ||
            (c.lastMessage &&
              c.lastMessage.message &&
              c.lastMessage.message
                .trim()
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.filter.text
                    .trim()
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, ""),
                ))
          );
        });
      }

      if (this.filter.isOnline === false) {
        contacts = contacts.filter((c) => c.isOnline === false);
      }

      if (this.filter.isOffline === false) {
        contacts = contacts.filter((c) => c.isOnline === true);
      }

      if (this.filter.unreadOnly === true) {
        contacts = contacts.filter(
          (c) => c.lastMessage && c.lastMessage.read === false,
        );
      }

      return contacts;
    },
  },

  methods: {
    updateFilter(filter) {
      this.filter = filter;
    },
    toggleContactBar() {
      this.showDrawer = !this.showDrawer;
    },
    getDrawerState() {
      return this.showDrawer;
    },
  },
};
</script>
