<template>
  <v-card color="background2" tile flat class="px-2">
    <div class="d-flex align-center">
      <v-img
        src="../assets/img/logoh.svg"
        max-width="170"
        contain
        class="py-3"
      />
    </div>
    <div class="focus-elem">
      <v-text-field
        v-model="text"
        color="tertiary"
        dense
        solo
        flat
        hide-details
        class="source-sans-pro primary-text-color fs-field my-1"
        placeholder="Pesquisa"
        @input="handleEmit"
      >
        <template v-slot:append>
          <v-icon v-if="text" small @click="handleTextClean()">
            mdi-close
          </v-icon>
        </template>
      </v-text-field>
    </div>

    <div class="d-flex justify-left py-3">
      <!-- <v-checkbox
        v-model="isOnline"
        dense
        hide-details
        class="my-0 py-0 pr-3"
        @change="handleEmit"
      >
        <template #label>
          <span class="roboto fs-label-checkbox checkbox-label-text-color">
            Online
          </span>
        </template>
      </v-checkbox> -->
      <!-- <v-checkbox
        v-model="isOffline"
        dense
        hide-details
        class="my-0 py-0 pr-3"
        @change="handleEmit"
      >
        <template #label>
          <span class="roboto fs-label-checkbox checkbox-label-text-color">
            Offline
          </span>
        </template>
      </v-checkbox> -->
      <v-checkbox
        v-model="unreadOnly"
        dense
        hide-details
        class="my-0 py-0 pr-3"
        @change="handleEmit"
      >
        <template #label>
          <span class="roboto fs-label-checkbox checkbox-label-text-color">
            Por ler
          </span>
        </template>
      </v-checkbox>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

import AuthService from "@/services/Auth";

export default {
  name: "ContactBarFilter",

  data: () => ({
    text: "",
    isOnline: true,
    isOffline: true,
    unreadOnly: false,
  }),

  methods: {
    ...mapMutations({
      resetStorage: "reset_storage",
    }),
    handleEmit() {
      this.$emit("update-filter", {
        text: this.text,
        isOnline: this.isOnline,
        isOffline: this.isOffline,
        unreadOnly: this.unreadOnly,
      });
    },
    handleTextClean() {
      this.text = "";
      this.handleEmit();
    },
  },
};
</script>
