<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <slot justify="rigth" :attrs="attrs" :on="on"></slot>
    </template>
    <v-card class="pa-5" color="modalbackground">
      <v-card-title class="pb-1 px-4 d-flex align-start">
        <div>
          <div class="primary-text-color source-sans-pro fs-title semi-bold">
            Contactos & Ocorrências
          </div>
          <div class="source-sans-pro fs-secondary">
            {{ activeContact.clientName }} - {{ activeContact.localName }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-icon small @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="mt-3">
          <div
            class="
              primary-text-color
              semi-bold
              source-sans-pro
              fs-label
              d-flex
              align-center
            "
          >
            Contactos
            <v-btn
              class="ml-1"
              icon
              x-small
              color="primary"
              @click="updateContacts"
            >
              <v-icon x-small>mdi-content-save</v-icon>
            </v-btn>
          </div>
          <v-textarea
            v-model="info.contacts"
            auto-grow
            no-resize
            label=" "
            color="black"
            dense
            solo
            flat
            hide-details
            rows="2"
            class="
              source-sans-pro
              primary-text-color
              fs-field
              pt-1
              mb-1
              focus-elem
              chat-scroll
            "
          >
            <template v-slot:append>
              <v-icon v-if="info.contacts" small @click="info.contacts = ''"
                >mdi-close
              </v-icon>
            </template>
          </v-textarea>
        </div>
        <div class="mt-3">
          <div
            class="
              primary-text-color
              semi-bold
              source-sans-pro
              fs-label
              d-flex
              align-center
            "
          >
            Ocorrências
            <v-btn
              class="ml-1"
              icon
              x-small
              color="primary"
              @click="createNewOcorrency"
            >
              <v-icon x-small>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              class="ml-1"
              icon
              x-small
              color="primary"
              @click="updateOcorrency"
            >
              <v-icon x-small>mdi-content-save</v-icon>
            </v-btn>
          </div>
          <v-row>
            <v-col cols="3">
              <v-card
                color="white"
                height="128px"
                class="
                  mt-1
                  source-sans-pro
                  primary-text-color
                  fs-field
                  d-flex
                  flex-column
                  chat-scroll
                "
                style="border: 1px #d4deea solid !important"
                elevation="0"
              >
                <v-treeview
                  id="treeviewOco"
                  dense
                  selected-color="primary"
                  :items="info.ocorrencies"
                  style="overflow-y: scroll"
                  :transition="true"
                  class="flex-grow-1"
                  hoverable
                >
                  <template slot="label" slot-scope="{ item }">
                    <v-tooltip v-if="item.DataHora" bottom open-delay="250">
                      <template v-slot:activator="{ on: on, attrs: attrs }">
                        <div
                          :class="{
                            bold:
                              activeOcorrency.IdOcorrencia ===
                              item.IdOcorrencia,
                          }"
                          style="cursor: pointer"
                          v-bind="attrs"
                          @click="setActiveOccurency(item)"
                          v-on="on"
                        >
                          {{ formatTitle(item.DataHora + "Z") }}
                        </div>
                      </template>
                      {{ formatTitle(item.DataHora + "Z") }}
                    </v-tooltip>
                  </template>
                </v-treeview>
              </v-card>
            </v-col>
            <v-col cols="9">
              <v-textarea
                v-model="activeOcorrency.Descricao"
                auto-grow
                no-resize
                label=" "
                color="black"
                dense
                solo
                flat
                hide-details
                rows="5"
                class="
                  source-sans-pro
                  primary-text-color
                  fs-field
                  pt-1
                  mb-1
                  focus-elem
                  chat-scroll
                "
              >
                <template v-slot:append>
                  <v-icon
                    v-if="activeOcorrency.Descricao"
                    small
                    @click="activeOcorrency.Descricao = ''"
                    >mdi-close
                  </v-icon>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import { mapGetters } from "vuex";

import ChatService from "@/services/Chat";

export default {
  name: "ChatHeaderContacts",

  props: {
    snackbar: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    dialog: false,
    info: "",
    infoList: [],
    activeOcorrency: {},
  }),

  computed: {
    ...mapGetters(["activeContact"]),
  },

  watch: {
    async dialog() {
      if (!this.activeContact) return;

      if (
        this.activeContact.clientId === null ||
        this.activeContact.localId === null
      )
        return;

      const idx = `C${this.activeContact.clientId}L${this.activeContact.localId}`;
      if (Object.keys(this.infoList).includes(idx)) {
        this.info = this.infoList[idx];
      } else {
        ChatService.getInfo(
          this.activeContact.clientId,
          this.activeContact.localId,
        )
          .then((response) => {
            this.info = response.data;
            this.infoList[idx] = response.data;
            if (this.info.ocorrencies.length > 0) {
              this.setActiveOccurency(this.info.ocorrencies[0]);
            }
          })
          .catch(() => {
            this.snackbar.show = false;
            this.$nextTick(() => {
              this.snackbar.show = true;
              this.snackbar.text =
                "Ocorreu um erro a enviar pedido para o servidor!";
              this.snackbar.color = "error";
              this.snackbar.timeout = 5000;
            });
          });
      }
    },
  },
  methods: {
    updateContacts() {
      ChatService.updateContacts(
        this.activeContact.clientId,
        this.activeContact.localId,
        this.info.contacts,
      )
        .then(() => {
          this.snackbar.show = false;
          this.$nextTick(() => {
            this.snackbar.show = true;
            this.snackbar.text = "Contactos alterados com sucesso!";
            this.snackbar.color = "success";
            this.snackbar.timeout = 5000;
          });
        })
        .catch(() => {
          this.snackbar.show = false;
          this.$nextTick(() => {
            this.snackbar.show = true;
            this.snackbar.text =
              "Ocorreu um erro a enviar pedido para o servidor!";
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
          });
        });
    },
    updateOcorrency() {
      ChatService.updateOccorrency(
        this.activeOcorrency.IdOcorrencia,
        this.activeOcorrency.Descricao,
      )
        .then(() => {
          this.snackbar.show = false;
          this.$nextTick(() => {
            this.snackbar.show = true;
            this.snackbar.text = "Ocorrência alterada com sucesso!";
            this.snackbar.color = "success";
            this.snackbar.timeout = 5000;
          });
        })
        .catch(() => {
          this.snackbar.show = false;
          this.$nextTick(() => {
            this.snackbar.show = true;
            this.snackbar.text =
              "Ocorreu um erro a enviar pedido para o servidor!";
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
          });
        });
    },
    createNewOcorrency() {
      ChatService.newOccorrence(
        this.activeContact.clientId,
        this.activeContact.localId,
      )
        .then((occorrence) => {
          this.info.ocorrencies.unshift(occorrence.data);
          this.setActiveOccurency(this.info.ocorrencies[0]);
        })
        .catch(() => {
          this.snackbar.show = false;
          this.$nextTick(() => {
            this.snackbar.show = true;
            this.snackbar.text =
              "Ocorreu um erro a enviar pedido para o servidor!";
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
          });
        });
    },
    setActiveOccurency(item) {
      this.activeOcorrency = item;
    },
    formatTitle(title) {
      return format(parseISO(title), "dd-MM-yyyy HH:mm");
    },
  },
};
</script>
