<template>
  <v-list-item class="px-3" @click="handleClick">
    <v-list-item-content
      :class="{ 'semi-bold': !read && message }"
      class="py-1 source-sans-pro fs-field"
    >
      <v-list-item-title>
        <div class="d-flex align-center">
          <!-- <v-avatar
            role="none"
            :color="isOnline ? 'green' : 'grey'"
            size="10"
            class="mr-2"
          >
          </v-avatar> -->
          <span class="elipsis">
            {{ clientName + " - " + localName }}
          </span>
          <v-avatar
            v-if="!read && message"
            role="none"
            :color="handleAvatarColor()"
            size="12"
            class="ml-auto white--text"
          >
          </v-avatar>
        </div>
      </v-list-item-title>

      <v-list-item-subtitle>
        <div class="d-flex align-center">
          <div v-if="message" class="elipsis">
            {{
              message && message.startsWith("@*@")
                ? message.substring(3)
                : message
            }}
          </div>
          <div v-else>
            <div>-</div>
          </div>
          <div v-if="date" class="ml-auto">
            {{ handleDate(date) }}
          </div>
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { isToday } from "date-fns";
import { mapGetters } from "vuex";

import { formatDate } from "@/utils/formatDate";

export default {
  name: "ContactBarListItem",

  props: {
    clientId: {
      type: Number,
      default: () => null,
    },
    clientName: {
      type: String,
      default: () => null,
    },
    localId: {
      type: Number,
      default: () => null,
    },
    localName: {
      type: String,
      default: () => null,
    },
    isOnline: {
      type: Boolean,
      default: () => false,
    },
    msgId: {
      type: Number,
      default: () => null,
    },
    message: {
      type: String,
      default: () => null,
    },
    read: {
      type: Boolean,
      default: () => true,
    },
    date: {
      type: Date,
      default: () => null,
    },
    fromCentral: {
      type: Boolean,
      default: () => null,
    },
  },

  computed: {
    ...mapGetters(["activeContact"]),
  },

  methods: {
    formatDate,
    handleClick() {
      if (
        this.activeContact.clientId === this.clientId &&
        this.activeContact.localId === this.localId
      )
        return;
      this.$router.push({
        path: "",
        query: { clientId: this.clientId, localId: this.localId },
      });
    },
    handleDate(date) {
      if (isToday(date)) {
        return formatDate(date, "HH:mm");
      } else {
        return formatDate(date, "dd/MM/yyyy");
      }
    },
    handleAvatarColor() {
      if (this.fromCentral === true) return "grey";
      return "primary";
    },
  },
};
</script>
