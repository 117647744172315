var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"elevation-default pl-4",attrs:{"fixed":"","dense":"","tile":"","color":"white","app":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",class:{ 'active-btn primary--text': _vm.getContactDrawerStatus() },attrs:{"small":"","icon":""},on:{"click":_vm.toggleContactBar}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-book-open-blank-variant")])],1)]}}])},[_c('span',[_vm._v("Mostrar / Esconder contactos")])]),_c('span',{staticClass:"source-sans-pro semi-bold fs-header"},[_vm._v(" "+_vm._s(_vm.activeContact.clientName)+" - "+_vm._s(_vm.activeContact.localName)+" ")]),_c('v-spacer'),_c('chat-header-contacts',{attrs:{"snackbar":_vm.snackbar},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onDialog = ref.on;
var attrsDialog = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""}},'v-btn',Object.assign({}, attrs, attrsDialog),false),Object.assign({}, on, onDialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-contacts")])],1)]}}],null,true)},[_vm._v(" Contactos ")])]}}])}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'active-btn primary--text': _vm.getTemplateDrawerStatus() },attrs:{"small":"","icon":""},on:{"click":_vm.toggleTemplateBar}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-menu")])],1)]}}])},[_c('span',[_vm._v("Mostrar / Esconder templates")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }