<template>
  <v-list class="pa-0">
    <v-list-item-group
      active-class="primary--text"
      mandatory
      :value="selectActiveContact()"
    >
      <template v-for="(contact, index) in contacts">
        <contact-bar-list-item
          :key="index"
          :client-id="contact.clientId"
          :client-name="contact.clientName"
          :local-id="contact.localId"
          :local-name="contact.localName"
          :is-online="contact.isOnline"
          :msg-id="contact.lastMessage.id"
          :message="contact.lastMessage.message"
          :read="contact.lastMessage.read"
          :date="contact.lastMessage.date"
          :from-central="contact.lastMessage.fromCentral"
        />
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

import ContactBarListItem from "@/components/ContactBarListItem";

export default {
  name: "ContactBarList",

  components: { ContactBarListItem },

  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters(["activeContact"]),
  },

  methods: {
    selectActiveContact() {
      for (let i = 0; i < this.contacts.length; i++) {
        const c = this.contacts[i];
        if (
          c.clientId === this.activeContact.clientId &&
          c.localId === this.activeContact.localId
        )
          return i;
      }

      return 0;
    },
  },
};
</script>
